<template>
  <div>
    <div class="box">
      <div class="head-box">
        <ul>
          <li class="head-li first">
            <img class="li-img" src="../assets/img/icon/Frame21.png" alt="" />
            <span class="navsp">账户金额</span>
            <h2 class="balance">¥{{ companyinfo.account }}</h2>
            <button class="deposit" @click="ti" v-if="getismain">提现</button>
          </li>
          <li class="head-li">
            <img
              class="li-img"
              src="../assets/img/slices6/finance-icon-one.png"
              alt=""
            />
            <span class="navsp">应收金额</span>
            <h2 class="balance">¥{{ companyinfo.total1 }}</h2>
          </li>
          <li class="head-li">
            <img
              class="li-img"
              src="../assets/img/slices6/finance-icon-two.png"
              alt=""
            />
            <span class="navsp">应付金额</span>
            <h2 class="balance">¥{{ companyinfo.total2 }}</h2>
          </li>
          <li class="head-li">
            <img
              class="li-img"
              src="../assets/img/slices6/finance-icon-three.png"
              alt=""
            />
            <span class="navsp">挂账金额</span>
            <h2 class="balance">¥{{ companyinfo.total3 }}</h2>
          </li>
          <li class="head-li fianlly">
            <img
              class="li-img"
              src="../assets/img/slices6/finance-icon-four.png"
              alt=""
            />
            <span class="navsp">收现金额</span>
            <h2 class="balance">¥{{ companyinfo.total4 }}</h2>
          </li>
        </ul>
      </div>

      <div class="nav-box">
        <div class="option">
          <div class="mt-4">
            <el-checkbox-group v-model="group" size="large">
              <el-checkbox-button
                :style="btt"
                v-for="city in cities"
                :key="city.id"
                :label="city.id"
              >
                {{ city.name }}
              </el-checkbox-button>
            </el-checkbox-group>

            <!-- 技师 -->
            <div class="jishi" v-if="getismain">
              <el-select
                filterable
                class="input-unit"
                v-model="tech"
                placeholder="技师"
              >
                <el-option
                  v-for="item in techlist"
                  :key="item.uid"
                  :label="item.nickname"
                  :value="item.uid"
                >
                </el-option>
              </el-select>
            </div>
            <!-- 调度 -->
            <div class="zhong" v-if="getismain">
              <el-select
                filterable
                class="input-unit"
                v-model="kefu"
                placeholder="调度"
              >
                <el-option
                  v-for="item in kefulist"
                  :key="item.uid"
                  :label="item.nickname"
                  :value="item.uid"
                >
                </el-option>
              </el-select>
            </div>
            <!-- 业务员 -->
            <div class="zhong" v-if="getismain">
              <el-select
                filterable
                class="input-unit"
                v-model="saleman"
                placeholder="业务员"
              >
                <el-option
                  v-for="item in saleslist"
                  :key="item.uid"
                  :label="item.nickname"
                  :value="item.uid"
                >
                </el-option>
              </el-select>
            </div>
          </div>

          <div class="left-screen" v-if="isOp">
            <el-select
              filterable
              v-model="srccid"
              placeholder="合作单位"
              v-if="isOp"
            >
              <el-option
                v-for="item in heZuoOptions"
                :key="item.cid"
                :value="item.cid"
                :label="item.name"
              >
                {{ item.name }}
              </el-option>
            </el-select>
            <!-- <img
              class="screen-img"
              src="../assets/img/ding/shuaxin.png"
              alt=""
            />
            <p class="screen-p">刷新</p> -->
            <el-select v-model="chargetype" placeholder="收费类型">
              <el-option label="挂账" value="1"></el-option>
              <el-option label="现金" value="2"></el-option>
              <el-option label="平台单" value="3" v-if="getismain"></el-option>
            </el-select>
          </div>
        </div>
        <div class="import">
          <div class="importleft">
            <!-- <el-select
              filterable
              v-model="srccid"
              placeholder="请选择合作单位"
              v-if="getismain"
            >
              <el-option
                v-for="item in heZuoOptions"
                :key="item.cid"
                :value="item.cid"
                :label="item.name"
              >
                {{ item.name }}
              </el-option>
            </el-select> -->

            <!-- 创建时间 -->
            <el-form ref="form">
              <el-form-item label="创建时间">
                <el-col :span="11">
                  <el-date-picker
                    v-model="value3"
                    value-format="YYYY-MM-DD"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                  >
                  </el-date-picker>
                </el-col>
              </el-form-item>
            </el-form>
            <!-- 完成时间 -->
            <el-form ref="form">
              <el-form-item label="完成时间">
                <el-col :span="11">
                  <el-date-picker
                    v-model="value2"
                    value-format="YYYY-MM-DD"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                  >
                  </el-date-picker>
                </el-col>
              </el-form-item>
            </el-form>
            <!-- 时间 -->
            <el-form ref="form">
              <el-form-item label="救援时间">
                <el-col :span="11">
                  <el-date-picker
                    v-model="value1"
                    value-format="YYYY-MM-DD"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="起始时间"
                    end-placeholder="结束时间"
                  >
                  </el-date-picker>
                </el-col>
              </el-form-item>
            </el-form>

            <!-- <el-date-picker
              v-model="value1"
              type="daterange"
              value-format="YYYY-MM-DD"
              range-separator="To"
              start-placeholder="起始时间"
              end-placeholder="结束时间"
            >
            </el-date-picker> -->
          </div>

          <div class="seek">
            <el-select
              filterable
              :style="unit"
              v-model="keytype"
              placeholder="下拉选择"
            >
              <el-option
                v-for="item in sousuo"
                :key="item.value"
                :value="item.value"
                :label="item.label"
              >
              </el-option>
            </el-select>
            <input
              class="sekint"
              type="text"
              @keyup.enter="lookfor"
              v-model="keyword"
              placeholder="输入关键字"
            />
            <button class="chaxun" @click="lookfor">查询</button>

            <div class="export" @click="export1" v-if="canExport">
              <p class="export-p">导出</p>
            </div>
            <div class="export" @click="renew">
              <p class="export-p">重置</p>
            </div>
          </div>
        </div>
      </div>

      <div class="main-box">
        <div class="wealthlist">
          <el-table :data="list" stripe height="98%" width="100%">
            <el-table-column label="编号" width="170">
              <template #default="scope">
                <span class="xiangqing" @click="dd(scope.row.id)">{{ scope.row.id }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="rescueTime" label="救援时间" width="150" />
            <el-table-column label="合作单位/服务项目" width="200">
              <template #default="scope">
                {{ scope.row.companyname }}
                <br />
                <i>{{ scope.row.rescuetypetxt }}</i>
              </template>
            </el-table-column>
            <el-table-column label="车主信息" width="180">
              <template #default="scope">
                <span v-html="scope.row.ownertxt"></span>
              </template>
            </el-table-column>
            <el-table-column label="位置" width="300">
              <template #default="scope">
                {{ scope.row.address }}
              </template>
            </el-table-column>

            <!--<el-table-column label="距离" width="70">
              <template #default="scope">
                {{ scope.row.yingshou }}
              </template>
            </el-table-column>
            <el-table-column label="费用" width="70">
              <template #default="scope">
                {{ scope.row.yingshou }}
              </template>
            </el-table-column>-->
            <el-table-column label="接单人信息" width="200">
              <template #default="scope">
                <span v-html="scope.row.take_user"></span>
              </template>
            </el-table-column>
            <el-table-column label="接单人车牌" width="100">
              <template #default="scope">
                {{ scope.row.take_car_number }}
              </template>
            </el-table-column>
            <el-table-column label="应收" width="80">
              <template #default="scope">
                {{ scope.row.yingshou }}
              </template>
            </el-table-column>
            <el-table-column label="应付" width="80">
              <template #default="scope">
                {{ scope.row.yingfu }}
              </template>
            </el-table-column>
            <el-table-column label="挂账" width="80">
              <template #default="scope">
                {{ scope.row.guazhang }}
              </template>
            </el-table-column>
            <el-table-column label="收现" width="80">
              <template #default="scope">
                {{ scope.row.car_pay }}
              </template>
            </el-table-column>
            <el-table-column label="流量费" width="80" v-if="getismain">
              <template #default="scope">
                {{ scope.row.plat_fee }}
              </template>
            </el-table-column>
            <el-table-column label="自有单号" width="250" v-if="isShow">
              <template #default="scope">
                {{ scope.row.iflow }}
                <br />
                {{ scope.row.iflow_money }}
              </template>
            </el-table-column>
            <el-table-column label="平台单号" width="250" v-if="isShow">
              <template #default="scope">
                {{ scope.row.flow }}
                <br />
                {{ scope.row.flow_money }}
              </template>
            </el-table-column>

            <el-table-column fixed="right" label="操作" v-if="getismain">
              <template #default="scope">
                <!-- <el-button
                  link
                  type="primary"
                  size="small"
                  @click="dd(scope.row.id)"
                  >查看</el-button
                > -->
                <el-button
                  link
                  type="primary"
                  size="small"
                  @click="settle(scope.row.id)"
                  v-if="scope.row.issettle == 0"
                  >入账</el-button
                >
                <el-button
                  link
                  type="primary"
                  size="small"
                  style="background: #bcbcbc; border: 0"
                  v-if="scope.row.issettle == 1"
                  >已入账</el-button
                >
                <el-button link type="primary" size="small">核算</el-button>
                <el-button
                  link
                  type="primary"
                  size="small"
                  v-if="scope.row.canEdit"
                  @click="xiu(scope.row.id)"
                  >修改</el-button>
                  <el-button
                  link
                  type="primary"
                  size="small"
                  disabled
                  style="background: #bcbcbc; border: 0"
                  v-if="!scope.row.canEdit"
                  >修改</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="allNum">总共{{ this.total }}条</div>
      </div>
    </div>
    <part :islook="look" :id="detailid"></part>
    <deposit :isTi="xian" @tixians="sxx"></deposit>
    <modifypart
      v-if="xiupart"
      :id="detailid"
      @closepart="closepart"
    ></modifypart>
  </div>
</template>

<script>
import { reactive, ref } from "vue";
import affairs from "../components/affairs.vue";
import * as api from "../api/order";
import Part from "../components/part.vue";
import Deposit from "../components/deposit.vue";
import * as index from "../api/index";
import { ElMessageBox } from "element-plus";
import Modifypart from "../components/modifypart.vue";

const cities = [
  {
    id: 1,
    name: "非事故拖车",
  },
  {
    id: 2,
    name: "事故拖车",
  },
  {
    id: 3,
    name: "搭电",
  },
  {
    id: 4,
    name: "换胎",
  },
  {
    id: 5,
    name: "送水",
  },
  {
    id: 6,
    name: "送油",
  },
  {
    id: 7,
    name: "充气",
  },
  {
    id: 8,
    name: "快修",
  },
  {
    id: 9,
    name: "开锁",
  },
  {
    id: 10,
    name: "吊车",
  },
  {
    id: 11,
    name: "困境",
  },
  {
    id: 12,
    name: "地库",
  },
];
const value1 = ref("");

export default {
  components: { affairs, Part, Deposit, Modifypart },
  data() {
    return {
      srccid:0,
      group: [],
      keytype: "",
      keyword: "",
      jishi: "",
      diaodu: "",
      yewu: "",
      value1: "",
      value2: "",
      value3: "",
      cities,
      heZuoOptions: [],
      list: [],
      chargetype: "",
      total: "",
      look: 0,
      xian: 0,
      detailid: "",
      ismain: "",
      companyinfo: {
        account: 0,
        total1: 0,
        total2: 0,
        total3: 0,
        total4: 0,
        total5: 0,
      },
      datas: {
        page: 1,
        perpage: 10,
        srccid: "",
        chargeType: "",
        createTimeBegin: "",
        createTimeEnd: "",
        finishTimeBegin: "",
        finishTimeEnd: "",
        rescueTimeBegin:"",
        rescueTimeEnd:"",
        rescueType: "",
        keyword: "",
        tech: "",
        kefu: "",
        saleman: "",
        keytype: "",
      },
      kefu: "",
      kefulist: [],
      saleman: "",
      saleslist: [],
      tech: "",
      techlist: [],
      isOp:true,
      timer: null,
      isChange: true,
      isShow: false,
      sousuo: [
        {
          value: "1",
          label: "订单号",
        },
        {
          value: "2",
          label: "车主信息",
        },
        {
          value: "3",
          label: "技师信息",
        },
        {
          value: "4",
          label: "任务点",
        },
        {
          value: "5",
          label: "目的地",
        },
        {
          value: "6",
          label: "合作商",
        },
        {
          value: "7",
          label: "案件号",
        },
      ],

      xiupart: false,
      canExport:false,
    };
  },
  async created() {

    let res = await api.getOrderListOp();
    this.heZuoOptions = res.data.splist;
    this.isShow = res.data.isShow;
    this.isOp = res.data.isMain;

    let res1 = await index.gettechlist();
    this.techlist = res1.data;
    this.canExport = res1.financeExport;

    let res2 = await index.getsaleslista();
    this.saleslist = res2.data;

    let res3 = await index.getkefulist();
    this.kefulist = res3.data;


    let { cid,st,et,type } = this.$route.query;
    //console.log("合作商过来的路由传参：", cid);
    if (cid != undefined && cid != "") {
      this.srccid = cid;
      this.lookfor();
    }else if(st != undefined && st != "" && et != undefined && et != "" && type != undefined && type != ''){
      if(type == 1)
      {
          this.value2 = [st,et];
          this.srccid = -999;
          this.lookfor();
      }else{
        this.value2 = [st,et];
        this.tech = -1;  
        this.lookfor();
      }
    } else {
      this.getlist(this.datas);
    }
  },
  methods: {
    dd(id) {
      this.look++;
      if (this.look == 3) {
        this.look = 1;
      }
      this.detailid = id;
    },
    xiu(id) {
      this.xiupart = true;
      this.detailid = id;
    },
    closepart() {
      this.xiupart = false;
      this.sx();
    },
    settle(id) {
      ElMessageBox.confirm("确认入账吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let xiures = await api.settle(id);
          //console.log("修改数据---", xiures);
          this.$message.success("入账成功");
          this.sx();
        })
        .catch(() => {
          this.$message.info("取消入账");
        });
    },
    async ti() {
      let res = await index.getTixian();

      console.log("---------------", res);

      if (res.code == 1) {
        this.xian++;
        if (this.xian == 3) {
          this.xian = 1;
        }
      } else {
        this.$message.warning(res.message);
      }
    },
    search3() {
      this.datas.keyword = this.keyword;
      this.getlist(this.datas);
    },
    async getlist(datas) {
      let res = await api.getFinancelist(datas);
      console.log(res);
      this.list = res.data;
      this.total = parseInt(res.total);
      this.companyinfo = res.companyinfo;
    },
    sx() {
      this.datas.page = 1;
      this.getlist(this.datas);
    },
    sxx() {
      this.getlist(this.datas);
    },
    export1() {
      let data = {
        page: 1,
        perpage: 10,
        srccid: this.srccid,
        chargeType: this.chargetype,
        createTimeBegin: this.value3 == null || this.value3 == ''? "" : this.value3[0],
        createTimeEnd: this.value3 == null || this.value3 == ''? "" : this.value3[1],
        finishTimeBegin:this.value2 == null || this.value2 == ''? "" : this.value2[0],
        finishTimeEnd: this.value2 == null || this.value2 == ''? "" : this.value2[1],
        rescueTimeBegin:this.value1 == null || this.value1 == '' ? "" : this.value1[0],
        rescueTimeEnd: this.value1 == null || this.value1 == ''? "" : this.value1[1],
        rescueType: this.group,
        keyword: this.keyword,
        tech: this.tech,
        kefu: this.kefu,
        saleman: this.saleman,
        keytype: this.keytype,
      };
      this.datas = data;

      let ct = -1;
      let at = -1;
      let rt = -1;

      if(Object.keys(this.datas).length == 0 || (this.datas.createTimeBegin == '' && this.datas.createTimeEnd == '' && this.datas.finishTimeBegin == '' && this.datas.finishTimeEnd == '' && this.datas.rescueTimeBegin == '' && this.datas.rescueTimeEnd == ''))
      {
          this.$message.warning("请选择具体的导出日期");
          return false;
      }

      if(this.datas.createTimeBegin != '' && this.datas.createTimeEnd != '')
      {
        ct = (Date.parse(this.datas.createTimeEnd) - Date.parse(this.datas.createTimeBegin))/86400000;
      }

      if(this.datas.finishTimeBegin != '' && this.datas.finishTimeEnd != '')
      {
        at = (Date.parse(this.datas.finishTimeEnd) - Date.parse(this.datas.finishTimeBegin))/86400000;
      }


      if(this.datas.rescueTimeBegin != '' && this.datas.rescueTimeEnd != '')
      {
        rt = (Date.parse(this.datas.rescueTimeEnd) - Date.parse(this.datas.rescueTimeBegin))/86400000;
      }

      if(ct > 31)
      {
          this.$message.warning("创建时间导出不能超出1个月，请重新选择");
          return false;
      }

      if(at > 31)
      {
          this.$message.warning("完成时间导出不能超出1个月，请重新选择");
          return false;
      }

      if(rt > 31)
      {
          this.$message.warning("救援时间导出不能超出1个月，请重新选择");
          return false;
      }

      this.$msgbox({
        title: "消息",
        message: "是否确定导出",
        showCancelButton: true,
        closeOnClickModal: false,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "导出中...";
            setTimeout(async () => {
              let res = await api.getFinancelistExport(this.datas);
              console.log("打印结果", res);
              done();
              if (res.status == 200) {
                instance.confirmButtonLoading = false;
              }
            });
          } else {
            done();
          }
        },
      })
        .then(() => {
          this.$message.success("导出成功请保存");
        })
        .catch(() => {
          this.$message.warning("取消导出");
        });
    },
    lookfor() {
      let data = {
        page: 1,
        perpage: 10,
        srccid: this.srccid,
        chargeType: this.chargetype,
        createTimeBegin: this.value3 == null || this.value3 == ''? "" : this.value3[0],
        createTimeEnd: this.value3 == null || this.value3 == ''? "" : this.value3[1],
        finishTimeBegin:this.value2 == null || this.value2 == ''? "" : this.value2[0],
        finishTimeEnd: this.value2 == null || this.value2 == ''? "" : this.value2[1],
        rescueTimeBegin:this.value1 == null || this.value1 == '' ? "" : this.value1[0],
        rescueTimeEnd: this.value1 == null || this.value1 == ''? "" : this.value1[1],
        rescueType: this.group,
        keyword: this.keyword,
        tech: this.tech,
        kefu: this.kefu,
        saleman: this.saleman,
        keytype: this.keytype,
      };
      this.datas = data;

      console.log(this.datas);
      this.getlist(this.datas);
    },
    renew() {
      this.datas = {
        page: 1,
        perpage: 10,
        srccid: "",
        chargeType: "",
        createTimeBegin: "",
        createTimeEnd: "",
        finishTimeBegin:"",
        finishTimeEnd: "",
        rescueTimeBegin:"",
        rescueTimeEnd: "",
        rescueType: "",
        keyword: "",
        tech: "",
        kefu: "",
        saleman: "",
        keytype: "",
      };
      this.value1 = "";
      this.value2 = "";
      this.value3 = "";
      this.keytype = "";
      this.keyword = "";
      this.rescueType = "";
      this.srccid = "";
      this.chargetype = "";
      this.saleman = "";
      this.kefu = "";
      this.tech = "";
      this.getlist(this.datas);
    },
    prev_view() {
      console.log("---订单上一页", this.datas.page);
    },
    // 分页器
    next_view() {
      console.log("---订单下一页", this.datas.page);
    },
    // 点击页数时触发
    current_view(e) {
      this.datas.page = e;
      this.getlist(this.datas);
    },
  },
  computed: {
    getismain() {
      return this.$store.state.ismain;
    },
  },
  watch: {},

  mounted() {
    let wealthDiv = document.querySelector(".el-scrollbar__wrap");
    wealthDiv.addEventListener(
      "scroll",
      () => {
        if (this.timer) clearTimeout(this.timer);
        this.timer = setTimeout(async () => {
          let x = 10;
          if (
            wealthDiv.offsetHeight + wealthDiv.scrollTop >=
            wealthDiv.scrollHeight - x
          ) {
            if (this.isChange && this.list.length < this.total) {
              this.isChange = false;
              this.datas.page++;
              let res = await api.getFinancelist(this.datas);
              this.list = [...this.list, ...res.data];
              this.isChange = true;
              console.log('这里触发了');
            } else {
              this.$message.warning("没有更多了");
            }
          }
        }, 500);
      },
      true
    );
  },
};
</script>

<style>
.el-checkbox-button:first-child .el-checkbox-button__inner {
  border: 1px solid #dcdfe6;
}
.el-checkbox-button__inner {
  border: 1px solid #dcdfe6;
}
</style>

<style lang="less" scoped>
.elxuan {
  float: left;
}

.left {
  float: left;
  width: 280px;
  height: 30px;
  background: #ffffff;
  border: 1px solid #dadfe6;
  opacity: 1;
  border-radius: 2px;
  position: relative;
}
.jian {
  float: left;
  transform: rotate(90deg);
  margin-right: 17px;
  margin-top: 10px;
}
.xian {
  float: left;
  width: 0px;
  height: 14px;
  margin-top: 7px;
  margin-right: 14px;
  border: 1px solid #dadfe6;
  opacity: 1;
}
.sub {
  float: left;
  width: 250px;
  height: 30px;
  border: 0;
  outline: none;
  position: absolute;
  padding-left: 12px;
  box-sizing: border-box;
}
.jing {
  float: left;
  width: 30px;
  height: 30px;
  background: #f7f9fb;
  border: 1px solid #dadfe6;
  opacity: 1;
  border-radius: 2px;
  position: absolute;
  top: -1px;
  right: -1px;
  cursor: pointer;
}
.icon1 {
  // width: 14px;
  height: 14px;
  margin-left: 9px;
  margin-top: 8px;
  //   border: 2px solrgb(27, 28, 29)e96;
  border-radius: 50%;
  opacity: 1;
}
.icon-icon1:before {
  float: left;
  margin-top: 8px;
  margin-right: 10px;
}
.dingHao {
  float: left;
  // width: 42px;
  // height: 19px;
  font-size: 14px;
  margin-top: 16px;
  margin-left: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 0px;
  margin-right: 16px;
  color: #2a3346;
  opacity: 1;
}

.demo-date-picker {
  display: flex;
  width: 100%;
  padding: 0;
  flex-wrap: wrap;
}
.demo-date-picker .block {
  padding: 30px 0;
  text-align: center;
  border-right: solid 1px var(--el-border-color-base);
  flex: 1;
}
.demo-date-picker .block:last-child {
  border-right: none;
}
.demo-date-picker .demonstration {
  display: block;
  color: var(--el-text-color-secondary);
  font-size: 14px;
  margin-bottom: 20px;
}

.box {
  width: 1730px;
  height: 840px;
  margin: 0;
  margin-left: 190px;
  margin-top: 0px;
  padding: 30px;
  box-sizing: border-box;
  background-color: #f8fafc;
}
.nav-box {
  width: 1670px;
  // height: 160px;
  margin-bottom: 15px;
  background-color: #fff;
  // margin-top: 30px;
  padding: 15px 30px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.main-box {
  width: 1670px;
  height: 500px;
  margin-top: 20px;
  background-color: #fff;
}
.option {
  // float: left;
  width: 100%;
  // height: 100px;
  box-sizing: border-box;
  // margin-top: -33px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.import {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
}

.screen {
  height: 32px;
  float: right;
  // margin-top: -31px;
  display: flex;
}
.left-screen {
  float: left;
  // width: 100px;
  height: 40px;
  // border: 1px solid #2c68ff;
  opacity: 1;
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}
.screen-img {
  // float: left;
  width: 24px;
  height: 24px;
  margin-right: 16px;
  // margin-left: 27px;
  // margin-top: 12px;
  box-sizing: border-box;
}
.screen-p {
  float: left;
  // width: 32px;
  // height: 22px;
  font-size: 16px;
  // margin-top: 25px;
  font-family: PingFang SC;
  font-weight: 500;
  // line-height: 0px;
  color: #2c68ff;
  opacity: 1;
}

.option-label {
  float: left;
  line-height: 100px;
  margin-left: 30px;
  margin-right: 30px;
}
.mt-4 {
  display: flex;
  box-sizing: border-box;
  align-items: center;
}

.el-checkbox-button {
  margin-right: 10px;
  // margin-bottom: 12px;
  // height: 34px;
  background: #f7f9fb;
  // border: 1px solid #dadfe6;
  opacity: 1;
  border-radius: 2px;
}

.btn {
  height: 32px;
  opacity: 1;
}
.set {
  width: 80px;
  height: 40px;
  background: #2c68ff;
  opacity: 1;
  color: #fff;
  border-radius: 2px;
  border: 0;
  cursor: pointer;
}
.cancel {
  width: 80px;
  height: 40px;
  background: #ffffff;
  margin-left: 20px;
  border: 1px solid #727e96;
  opacity: 1;
  border-radius: 2px;
  cursor: pointer;
}

.head-box {
  width: 1670px;
  height: 120px;
  // background-color: pink;
  margin-bottom: 30px;
}

ul {
  list-style: none;
}
.head-li {
  float: left;
  width: 297px;
  height: 120px;
  padding-left: 30px;
  margin-right: 23px;
  box-sizing: border-box;
  background-color: #fff;
  box-shadow: 0px 3px 20px rgba(179, 189, 195, 0.16);
  opacity: 1;
  border-radius: 0px;
}
.fianlly {
  margin-right: 0;
}
.li-img {
  float: left;
  width: 24px;
  height: 24px;
  margin-top: 26px;
  margin-right: 10px;
}
.navsp {
  display: inline-block;
  // width: 80px;
  height: 21px;
  // font-size: 16px;
  margin-top: 38px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 0px;
  color: #727e96;
  opacity: 1;
}
.balance {
  padding-left: 33px;
}
.el-input {
  width: 200px;
  border: 0;
}

.first {
  width: 390px;
  background-color: #e9efff;
  position: relative;
}
.deposit {
  width: 90px;
  height: 32px;
  color: #fff;
  font-size: 16px;
  background: #2c68ff;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  border: 0;
  position: absolute;
  right: 36px;
  bottom: 29px;
  cursor: pointer;
}
// <!--内页-->
i {
  font-style: normal;
  color: #2c68ff;
}

.zhong /deep/ .el-select {
  width: 150px;
  height: 32px !important;
  margin-right: 20px;
}
.jishi /deep/ .el-select {
  width: 150px;
  height: 32px !important;
  margin-right: 20px;
}
.left-screen /deep/ .el-select {
  width: 120px;
  height: 32px !important;
  margin-left: 10px;
}

.mt-4 /deep/ .el-checkbox-button--large .el-checkbox-button__inner {
  padding: 8px 10px;
  box-sizing: border-box;
}

.wealthlist {
  height: 455px;
  position: relative;
}

.wealthlist /deep/ .el-table-fixed-column--right:last-of-type {
  width: 230px;
  display: flex;
  align-items: center;
  height: 70px;
}

.wealthlist /deep/ .el-table th.el-table__cell.is-leaf {
  background-color: #f5f5f5;
  color: #000;
  height: 50px;
}
.wealthlist /deep/ .el-table__row {
  height: 70px !important;
  box-sizing: border-box;
}

.wealthlist /deep/ .el-table__cell {
  padding: 0;
}
.wealthlist /deep/ .el-button--small {
  width: 60px;
}
.allNum {
  font-size: 14px;
  text-align: right;
  color: #2c68ff;
  height: 40px;
  line-height: 40px;
}

.seek {
  float: left;
  opacity: 1;
  box-sizing: border-box;
  border-radius: 2px;
  position: relative;
  display: flex;
}
.sekint {
  float: left;
  width: 156px;
  height: 32px;
  padding: 15px;
  outline: none;
  border: none;
  box-sizing: border-box;
  border: 1px solid #dadfe6;
  border-left: 0;
}
.seek /deep/ .el-select .el-input__inner {
  width: 110px;
  border-radius: 0;
}
.chaxun {
  width: 80px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #2c68ff;
  color: #fff;
  border: 0;
  cursor: pointer;
}
.export {
  display: flex;
  width: 80px;
  height: 32px;
  align-items: center;
  justify-content: center;
  border: 1px solid #727e96;
  box-sizing: border-box;
  margin-left: 20px;
  cursor: pointer;
}
.seek /deep/ .el-select {
  width: 100px !important;
  margin: 0 !important;
}
.importleft {
  display: flex;
  align-items: center;
}
.importleft /deep/ .el-select {
  width: 250px !important;
  margin: 0 !important;
  margin-right: 10px !important;
}
.importleft /deep/ .el-range-editor.el-input__inner {
  width: 250px;
  margin: 0 !important;
}
.importleft /deep/ .el-form-item {
  margin: 0 !important;
  margin-right: 20px !important;
}
.xiangqing {
  font-style: normal;
  color: #2c68ff;
  cursor: pointer;
}
</style>

